<template>
    <div class="min-h-full relative max-h-full flex flex-col text-base-content" v-allow:auth="'package.read'" >
        <sub-header :showBtn="showReplaceWorkflow" :handleClick="handleReplaceWorkflow" buttonText="Replace Workflow" :backButton="true" class="mb-4" backRedirectionUrl="/packages" :customCrumbLists="customBreadCumbs" :handleBackSection="setEditModeUnlock"/>
        <div class="w-full h-full overflow-hidden">
            <div class="flex h-full gap-4 flex-1 overflow-hidden">
                <div class="w-2/12 rounded-lg self-start bg-card-bg shadow flex flex-col gap-x-4 overflow-y-auto scroll-bar">
                    <div
                        @click="handleCurrentTab(nav)"
                        v-for="(nav, idx) in navigations"
                        :key="idx"
                        class="px-4 py-3 transition-all relative duration-200 items-center text-sm font-medium flex gap-3  cursor-pointer"
                        :class="{
                            ' bg-primary-300 hover:bg-primary-200 text-base-content-800': currentTab === nav.nav,
                            'hover:bg-gray-100   hover:text-base-content-600': currentTab !== nav.nav,
                            'border-t border-0' : idx != 0
                        }"
                    >
                        <!-- :style="{borderColor: currentTab === nav.nav ? '#cdcdcd': '' }" -->
                        <font-awesome-icon :icon="[nav.iconPrefix || 'fa', nav.icon]" />
                        {{ nav.title }}
                        <!-- <font-awesome-icon class="text-gray-500 absolute right-2" v-if="currentTab === nav.nav" icon="arrow-right" /> -->
                    </div>
                </div>
                <!-- TODO: loader -->
                <div class="flex flex-col flex-1 gap-3 w-full h-full overflow-hidden">
                    <Tabs
                        class="entity-type-tabs border-b w-full"
                        v-if="currentTabConfig.tab.entityTypeTabs && entityTypeTabs.length"
                        v-model="selectedEntityType"
                        :tabs="entityTypeTabs"
                        no-content
                    />
                    <!-- currentTab != 'workflow' &&  -->
                    <UISection
                        v-if="getPackage"
                        :class="{
                            'description_height': currentTab === 'description',
                            'overflow-y-hidden': currentTab !== 'description',
                        }"
                    >
                        <template #title>
                            <span>Package: </span>
                            <span class="font-normal">{{ originPackageData.package_name }}</span>
                        </template>
                    
                        <div
                            class="w-full scroll-bar"
                            style="max-height: 72vh;"
                        >
                    
                        </div>
                        <component
                            :is="currentTabConfig.component.el"
                            v-bind="currentTabConfig.component.props"
                        />
                    </UISection>
                    <div v-else-if="currentTab=='workflow'" class="flex-1">
                     <div>
                            <component
                                :is="currentTabConfig.component.el"
                                ref="workflow"
                                v-bind="currentTabConfig.component.props"
                            />
                        </div>
                    </div>
                    <div
                        v-else
                        class="flex flex-1 bg-card-bg p-3 h-full overflow-y-scroll scroll-bar justify-center items-center"
                    >
                        No Options Selected
                    </div>
                </div>
            </div>
        </div>
        <modal-confirm 
            title="Are you sure?" 
            message="Unsaved data will be lost. Please confirm to continue without saving."
            ref="confirm-popup"> 
        </modal-confirm>
    </div>
</template>
<script>
import SubHeader from "@/components/SubHeader";
import Button from "@/components/button";
import { default as rawAxios } from "@/axios";
// End
const Description = () => import("@/components/tenant-packages/description/description");
const Checks = () => import("@/components/tenant-packages/checks");
// const CandidateForm = () => import("@/components/tenant-packages/candidate-form-view");
// const RefereeForm = () => import("@/components/tenant-packages/referee-form-view");
// const ScreenerForm = () => import("@/components/tenant-packages/screener-form-view");
// const ClientForm = () => import("@/components/tenant-packages/client-form-view");
// const WelcomeScreen = () => import("@/components/tenant-packages/welcome-screen");
const FormEditor = () => import("@/components/tenant-packages/form-editor");

const Tat = () => import("@/components/tenant-packages/tat/tat");

const FormPreview = () => import("@/components/tenant-packages/form-preview");
const Audit = () => import("@/components/tenant-packages/audit");
const EmailSequence = () => import("@/components/tenant-packages/email-sequence")
const LinkSetting = () => import("@/components/tenant-packages/link-setting/index")
const Workflow = () => import("@/components/tenant-packages/workflow");
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { EventBus } from "@/main.js";
// const Email = () => import("@/components/tenant-packages/email/email");
import Tabs from "@/components/dashboard-components/tabs";
import { reorderPackageEntityTypes } from "@shared/utils/functions";
const modalConfirm = () => import("@/components/modal-confirm");

export default {
    title: 'Packages',
    name: "screeing-package-view",
    components: {
        SubHeader,
        "neo-button": Button,
        description: Description,
        checks: Checks,
        EmailSequence,
        LinkSetting,
        modalConfirm,
        // "email": Email,
        Tabs,
    },
    props: {},
    data: () => ({
        navigations: [
            {
                title: "Configuration",
                nav: "description",
                icon: "file-lines",
                subnav: false,
                iconPrefix: "far",
            },
            {
                title: "SLA",
                nav: "sla",
                icon: "clock",
                subnav: false,
                iconPrefix: "far",
            },
            {
                title: "Checks",
                nav: "checks",
                icon: "circle-check",
                subnav: false,
                iconPrefix: "far",
            },
            {
                title: "Form Editor",
                nav: "form-editor",
                icon: "table",
                subnav: false,
                entityTypeTabs: true,
            },
			{
                title: "Form Preview",
                nav: "form-preview",
                icon: "tv",
                subnav: false,
                entityTypeTabs: true,
            },
            {
                title: "Default Email Sequence",
                nav: "email",
                icon: "envelope",
                subnav: false,
                iconPrefix: "far",
            },
            {
                title: "Link Setting",
                nav: "link-setting",
                icon: "link",
                subnav: false,
            },
            // {
            //     title: "Welcome Screen",
            //     nav: "welcome-screen",
            //     icon: "desktop",
            //     subnav: false,
            // },
            {
                title: "Activity History",
                nav: "audit",
                icon: "history",
                subnav: false,
            },
            {
                title: "Workflow",
                nav: "workflow",
                icon: "file-lines",
                subnav: false,
                iconPrefix: "far",
            },

            // { title: "Client Form", nav: "client-form", icon: "user", subnav: false },
            
            // {
            //     title: "Candidate Form",
            //     nav: "candidate-form",
            //     icon: "user-friends",
            //     subnav: false,
            // },
            // {
            //     title: "Referee Form",
            //     nav: "referee-form",
            //     icon: "user-friends",
            //     subnav: false,
            // },
            // {
            //     title: "Analyst Form",
            //     nav: "screener-form",
            //     icon: "tv",
            //     subnav: false,
            // },

            // { title: 'Email', nav: 'email', icon: 'envelope', 'subnav': false,},
        ],
        currentTab: null,
        componentData: null,
        customBreadCumbs: [],
        selectedEntityType: null,
        isEntityTypesLoading: false,
    }),
    async mounted() {
        let roles = this.getRoles;
        if (!roles.length) {
            await this.fetchRoles();
        }
        if (!this.getPackage)
            await this.fetchPackage()
        
        this.resetSelectedEntityTab();

        await this.fetchEntityTypeOptions();
    },
    created() {
        let params = this.$route.params;
        console.log("params", params);

        // reset to update package data:
        this.RESET_PACKAGE()

        if (params.packagetool) {
            this.currentTab = params.packagetool;
            this.$route.meta.customCrumbs = [{ name: params.packagetool }];
            this.$forceUpdate();
        } else {
            this.currentTab = this.navigations[0].nav;
            this.$router.push({
                name: "Package Tools",
                params: { packagetool: this.navigations[0].nav },
            });
            this.setBredCrumbs(this.currentTab)
            this.$forceUpdate();
        }
    },
    computed: {
        ...mapGetters([
            "getPackage",
            "getRoles",
            "getPackageSections",
            "getPackageChecks",
            "getPackageForms",
            'originPackageData',
        ]),
        ...mapState({
            entityTypeOptions: 'entityTypeList',
            isDirty: state => state.PackageStore.isDirty,
            originPackageData: state => state.PackageStore.originPackageData,
        }), 
        subDomainAvailable() {
            let selectedTab = this.currentTab;
            if (selectedTab) {
                let currentNav = this.navigations.find((ele) => ele.nav === selectedTab);
                return currentNav.subnav;
            } else {
                return "";
            }
        },
        screeningMenuWrapper() {
            return {
                description: {
                    el: Description,
                    props: {
                        beforeLeave: this.beforeLeaveTab
                    }
                },
                checks: {
                    el: Checks,
                },
                "email": {
                    el: EmailSequence,
                },
                // "candidate-form": CandidateForm,
                // "referee-form": RefereeForm,
                sla: {
                    el: Tat,
                    props: {
                        beforeLeave: this.beforeLeaveTab
                    },
                },
                // "screener-form": ScreenerForm,
                // "client-form": ClientForm,
                // "welcome-screen": WelcomeScreen,
                "form-editor": {
                    el: FormEditor,
                    props: {
                        entityType: this.selectedEntityType,
                    },
                },
                "form-preview": {
                    el: FormPreview,
                    props: {
                        entityType: this.selectedEntityType,
                    },
                },
                'audit':{
                    el: Audit,
                },
                'link-setting': {
                    el: LinkSetting,
                },
                'workflow': {
                    el: Workflow,
                },
            }
        },
        currentTabConfig() {
            return {
                // need this to have access to the current tab config as we don't store it in this.currentTab
                tab: this.navigations.find((el) => el.nav === this.currentTab),
                component: this.screeningMenuWrapper[this.currentTab],
            }
        },
        entityTypeTabs() {
            const typeList = reorderPackageEntityTypes(this.getPackage?.entity_types)
            return typeList.map((el) => ({
                ...el,
                label: el.name,
            })) || []
        },
        showReplaceWorkflow() {
            return this.currentTab=='workflow' && this.$route.params.action!='view' && this.getWorkflowId;
        },
           getWorkflowId() {
      return this.$route.params?.packagetoolId
    },
    },

    methods: {
        ...mapMutations(["RESET_PACKAGE"]),
        handleReplaceWorkflow() {
            this.$refs.workflow.handleAddDefault()
        },
        ...mapActions([
            "fetchPackageData",
            "fetchRoles",
            "fetchPackageSections",
            "fetchPackageChecks",
            "fetchEntityTypeList"
        ]),
        // fetch all necessary package data:
        async fetchPackage() {
            const package_id = this.$route.params.packageName
            await Promise.all([
                this.fetchPackageData(package_id),
                this.fetchPackageSections(package_id),
                this.fetchPackageChecks(package_id),
            ])
        },
        async beforeLeaveTab() {
            if (!this.isDirty) {
                return true
            } else {
                const promise = await this.$refs["confirm-popup"].show({
                    title: "Are you sure?",
                    message: "Unsaved data will be lost. Please confirm to continue without saving.",
                });

                if (promise) {
                    await this.fetchPackage();
                }
                return !!promise;
            }
        },
        /***
         *  triggers when tabs changed
         */
        async handleCurrentTab(tab) {
            let shouldLeaveTab = await this.beforeLeaveTab();

            if (!shouldLeaveTab || this.currentTab === tab.nav) return;
            this.resetSelectedEntityTab();
            this.currentTab = tab.nav;
            this.setBredCrumbs(this.currentTab);
            if (this.currentTab!='workflow'){
                this.$router.push({
                name: "Package Tools",
                params: { packagetool: this.currentTab },
            });
            }
            else {
                 this.$router.push({
                name: "Package Tools",
                params: { packagetool: this.currentTab, type: "case" },
            });   
            }
        },
        setBredCrumbs(crumb) {
            this.customBreadCumbs = [{ name: crumb }];
        },
        async setEditModeUnlock() {
            if(this.$route.params.action == 'view'){
                return
            }
            try {
                if(this.$route.params?.packageName){
                    let package_id = this.$route.params?.packageName
                    let payload = { lock_status: false }
                    let url = `/screening-package/locked/${package_id}`;
                    await rawAxios.post(url, payload);
                    EventBus.$emit('fetchtenantpackage');
                    return true;
                }
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Failed to unlock this package");
                return false; // Return false when there's an error
            }
        },
        resetSelectedEntityTab() {
            if (this.entityTypeTabs.length) this.selectedEntityType = this.entityTypeTabs[0]
        },
        async fetchEntityTypeOptions() {
            this.isEntityTypesLoading = true;
            if (!this.entityTypeOptions.length) {
                await this.fetchEntityTypeList();
            }
            this.isEntityTypesLoading = false;
        },
    },
};
</script>
<style scoped lang="scss">
.screeing_nav {
    border: 1px solid transparent;
}
.screeing_nav:hover {
    border: 1px solid #cdcdcd;
}
::v-deep {
    .ql-container {
        .ql-editor {
            min-height: 20rem;
            height: 20rem;
        }
    }
}
.description_height{
height: fit-content;
}
.entity-type-tabs {
    flex: 0 0 0;
}
</style>
